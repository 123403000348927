import React from "react";
import ReactDom from "react-dom";
import App from "./App";
import WrapperProvider from "./contexts/WrapperProvider";

// @ts-ignore
ReactDom.render(
  <WrapperProvider>
    <App />
  </WrapperProvider>,
  document.getElementById("lineupcards-ui"),
);
