import React from "react";
import { Security } from "@okta/okta-react";
import { AuthContextProvider, oktaAuth } from "./AuthContext";
import { Theme } from "best-common-react";
import { LoadingContextProvider } from "./LoadingContext";
import HttpInterceptor from "../httpClients/HttpInterceptor";
import { ThemeProvider } from "styled-components";
import { AlertsProvider } from "../components/alerts/AlertsContext";
import { GamesContextProvider } from "./GamesContext";

const WrapperProvider = ({ children }) => {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(originalUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AuthContextProvider>
        <LoadingContextProvider>
          <HttpInterceptor>
            <ThemeProvider theme={Theme}>
              <AlertsProvider>
                <GamesContextProvider>{children}</GamesContextProvider>
              </AlertsProvider>
            </ThemeProvider>
          </HttpInterceptor>
        </LoadingContextProvider>
      </AuthContextProvider>
    </Security>
  );
};

export default WrapperProvider;
