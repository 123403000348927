import React, { useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import styled from "styled-components";
import { useGames } from "../../contexts/GamesContext";
import FileHelper from "../../helper/FileHelper";
import AuditApi from "../../httpClients/AuditApi";
import { isTBDDate, addToDate, formatDate, DateFormats } from "../../utils/DateUtils";
import { useAlert } from "../alerts/AlertsContext";
import Matchup from "../elements/Matchup";
import { PrimaryOutlinedButtonStyled } from "../elements/PrimaryOutlinedButton";
import GenerateButton from "../elements/GenerateButton";
import DownloadButton from "../elements/DownloadButton";

export const GamePkStyle = styled.div.attrs(() => {})`
  color: #007bff;
  cursor: pointer;
`;

export const GameDateFormatter = ({ value }: { value: string | null }) => {
  if (value && isTBDDate(value)) {
    const offsetDate = addToDate(value, 6, "hour");
    const formattedDate = formatDate(offsetDate, "ddd M/D");
    const tbdString = " - TBD";
    return (
      <div className="d-flex">
        <div>{formattedDate}</div> <div className="ml-1">{tbdString}</div>
      </div>
    );
  }

  return <div>{value ? formatDate(value, DateFormats.SHORT_DATETIME_WITH_TIMEZONE) : null}</div>;
};

type MatchUpFormatterProps = {
  value: {
    away: {
      team: string;
    };
    home: {
      team: string;
    };
  };
};

export const MatchUpFormatter = ({ value }: MatchUpFormatterProps) => {
  return <Matchup away={value.away.team} home={value.home.team} />;
};

type VenueFormatterProps = {
  value: {
    name: string;
  };
};

export const VenueFormatter = ({ value }: VenueFormatterProps) => {
  return <div>{value ? value.name : null}</div>;
};

export const DoubleHeaderFormatter = ({ row }: RowProps) => {
  if (row && (row.doubleHeader === "S" || row.doubleHeader === "Y") && !row.resumedFrom) {
    return <div>{row.gameNumber} of 2</div>;
  } else if (row && row.resumedFrom) {
    return <div>Susp.</div>;
  }
  return <div />;
};

export const RosterRulesText = styled.div.attrs(() => {})`
  color: ${(props: { color: any }) => props.color};
`;

type RowProps = {
  row: any;
};

export const RosterLimitFormatter = ({ row }: RowProps) => {
  const rulesOverride = row?.activeRosterCap?.rosterRulesOverride;
  const textColor = rulesOverride ? "red" : "black";

  const overrideOnClick = () => {
    if (row?.activeRosterCap) {
      row.activeRosterCap.gamePk = row.gamePk;
      row.activeRosterCap.gameDate = row.gameDate;
      row.setCurrentRosterSettings(row.activeRosterCap);
      if (rulesOverride) {
        row.setDeleteRosterRuleOverrideOpen(true);
      } else {
        row.setRosterOverrideModalOpen(true);
      }
    }
  };

  return (
    <>
      {row && row.isAdmin && row.hasRosterLimit && row.activeRosterCap ? (
        <div className="d-flex">
          {row.activeRosterCap.rosterCap === 0 || row.activeRosterCap.rosterCap === 99 ? (
            "Unlimited"
          ) : (
            <RosterRulesText color={textColor}>{row.activeRosterCap.rosterCap}</RosterRulesText>
          )}
          <RosterRulesText color={textColor}>/</RosterRulesText>
          {row.activeRosterCap.pitcherCap === 0 || row.activeRosterCap.pitcherCap === 99 ? (
            "Unlimited"
          ) : (
            <RosterRulesText color={textColor}>{row.activeRosterCap.pitcherCap}</RosterRulesText>
          )}
          <div style={{ color: "#007bff", cursor: "pointer" }} className="ml-4" onClick={overrideOnClick}>
            {rulesOverride ? "delete override" : "override"}
          </div>
        </div>
      ) : null}
    </>
  );
};

export const GamePkFormatter = ({ row }: RowProps) => {
  // @ts-ignore
  const { selectedOrg } = useGames();
  const versionUrls = row.teams?.team?.name === selectedOrg?.name ? row.homeStorageUrls : row.awayStorageUrls;
  return (
    <>
      {versionUrls && versionUrls.length > 0 ? (
        <GamePkStyle
          onClick={() => {
            row.setVersionsUrls(versionUrls);
            row.setVersionsModalOpen(true);
          }}
        >
          {row.gamePk}
        </GamePkStyle>
      ) : (
        <div>{row.gamePk}</div>
      )}
    </>
  );
};

export const PrintAuditFormatter = ({ row }: RowProps) => {
  const showAlert = useAlert();
  return (
    <>
      {row.isAdmin && row.hasAuditTrail ? (
        // @ts-ignore
        <PrimaryOutlinedButtonStyled
          height={26}
          width={150}
          onClick={() => {
            trackPromise(
              AuditApi.printAuditByGamePk(row.gamePk).then((response) => {
                if (response && response.data.size > 0) {
                  FileHelper.downloadFile(response, "pdf");
                } else {
                  showAlert("This game has no audit trail yet. Nothing to be printed.", "danger");
                }
              }),
            );
          }}
        >
          Download Audit Trail
        </PrimaryOutlinedButtonStyled>
      ) : null}
    </>
  );
};

export const HomeLineupStatusFormatter = ({ row }: RowProps) => {
  const { homeLineup } = row;

  let lineupStatus = "No Lineup";

  if (homeLineup?.players.length > 0 && homeLineup?.draft) {
    lineupStatus = "Unsubmitted Changes";
  } else if (homeLineup?.players.length > 0 && homeLineup?.draft != null && !homeLineup?.draft) {
    lineupStatus = "Submitted";
  }

  const textColor = lineupStatus === "Submitted" ? "green" : "red";

  return <div style={{ color: textColor }}>{lineupStatus}</div>;
};

export const AwayLineupStatusFormatter = ({ row }: RowProps) => {
  const { awayLineup } = row;

  let lineupStatus = "No Lineup";

  if (awayLineup?.players.length > 0 && awayLineup?.draft) {
    lineupStatus = "Unsubmitted Changes";
  } else if (awayLineup?.players.length > 0 && awayLineup?.draft != null && !awayLineup?.draft) {
    lineupStatus = "Submitted";
  }

  const textColor = lineupStatus === "Submitted" ? "green" : "red";

  return <div style={{ color: textColor }}>{lineupStatus}</div>;
};

export const DownloadCardsFormatter = ({ row }: RowProps) => {
  const downloadOnClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className="d-flex" style={{ cursor: "pointer" }}>
      <div className="mr-1" style={{ width: "75px" }}>
        <DownloadButton
          onClick={() => {
            downloadOnClick(row?.homeStorageUrls[row?.homeStorageUrls?.length - 1]);
          }}
          disabled={row?.homeStorageUrls?.length === 0}
          text={row.teams?.home?.team?.abbreviation?.toUpperCase()}
          height={26}
          width={100}
          primary
        />
      </div>
      <DownloadButton
        onClick={() => {
          downloadOnClick(row?.awayStorageUrls[row?.awayStorageUrls?.length - 1]);
        }}
        disabled={row?.awayStorageUrls?.length === 0}
        text={row?.teams?.away?.team?.abbreviation?.toUpperCase()}
        height={26}
        width={100}
        primary
      />
    </div>
  );
};
