import React from "react";
import styled from "styled-components";
import bg from "../assets/logo/bg.png";
import { PrimaryButtonStyled } from "../elements/PrimaryButton";
import { useOktaAuth } from "@okta/okta-react";
import { useAuth } from "../../contexts/AuthContext";
import { Redirect, useHistory } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";

const LoginBG = styled.div.attrs({})`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
`;

const LoginContainer = styled.div.attrs({
  className: "d-flex justify-content-center align-items-center",
})`
  height: calc(100vh - 30px);
`;

const LoginHolder = styled.div`
  height: 210px;
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.5);
  background-color: #f2f3f4;
  border: solid 1.25rem #ffffff;
  border: solid 1.25rem var(--white);
`;

const LoginFormHolder = styled.div.attrs({
  className: "d-flex justify-content-center",
})``;

const LoginTitle = styled.div.attrs({
  className: "mb-1 mt-3 text-align-center",
})`
  width: 100%;
  color: ${(props) => props.theme["mlb-blue"]};
  font-size: 3rem;
  font-weight: bold;
`;

const StyledIcon = styled.i.attrs(() => ({
  className: "fas fa-key",
}))`
  cursor: pointer;
  color: ${(props) => props.theme.wbcBlue};
  &&& {
    line-height: inherit;
  }
`;

const Login = () => {
  const { dispatch, state } = useAuth();
  const { oktaAuth } = useOktaAuth();

  const history = useHistory();

  const loginFn = () => {
    dispatch({ type: "login" });
    history.push(RouteConstants.BASE);
    oktaAuth.signInWithRedirect({ originalUri: RouteConstants.BASE });
  };

  return (
    <div>
      {!state.loggedIn ? (
        <>
          <LoginBG />
          <LoginContainer>
            <LoginHolder>
              <LoginFormHolder>
                <div>
                  <LoginTitle>Lineup Cards</LoginTitle>
                  <hr />
                  <div className="d-flex justify-content-center">
                    <PrimaryButtonStyled onClick={loginFn} height={29} width={213}>
                      <div className="d-flex align-items-center">
                        <StyledIcon iconName="fa fa-key" color="#ffffff" className="mr-2" />
                        Sign in with MLB SSO
                      </div>
                    </PrimaryButtonStyled>
                  </div>
                </div>
              </LoginFormHolder>
            </LoginHolder>
          </LoginContainer>
        </>
      ) : (
        <Redirect to={RouteConstants.BASE} />
      )}
    </div>
  );
};

export default Login;
