import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeaderWithTitleAndClose } from "best-common-react";
import { PrimaryOutlinedButtonStyled } from "../../elements/PrimaryOutlinedButton";
import styled from "styled-components";
import { FormInput } from "best-common-react";
import { PrimaryButtonStyled } from "../../elements/PrimaryButton";
import LineupCardApi from "../../../httpClients/LineupCardApi";
import { useAlert } from "../../alerts/AlertsContext";

const Message = styled.div.attrs({ className: "mb-3" })`
  font-size: 1rem;
  font-weight: normal;
  color: #77879a;
`;

const RosterRuleOverrideModal = ({ isOpen, onClose, currentRosterSettings, setUpdatedRosterOverride }) => {
  const [rosterMax, setRosterMax] = useState(currentRosterSettings?.rosterCap);
  const [pitcherMax, setPitcherMax] = useState(currentRosterSettings?.pitcherCap);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const showAlert = useAlert();

  const onCloseFn = () => {
    setRosterMax(currentRosterSettings?.rosterCap);
    setPitcherMax(currentRosterSettings?.pitcherCap);
    onClose(false);
  };

  const handleSaveOverride = () => {
    LineupCardApi.setAdminOverrideRule(
      currentRosterSettings?.gamePk,
      currentRosterSettings?.gameDate,
      rosterMax,
      pitcherMax,
    ).then(() => {
      setUpdatedRosterOverride(currentRosterSettings?.gamePk + "add");
      showAlert("Roster Rule Override Saved", "success");
      onClose(false);
    });
  };

  useEffect(() => {
    setSaveDisabled(
      !(rosterMax !== currentRosterSettings?.rosterCap || pitcherMax !== currentRosterSettings?.pitcherCap),
    );
  }, [rosterMax, pitcherMax]);

  useEffect(() => {
    setRosterMax(currentRosterSettings?.rosterCap);
    setPitcherMax(currentRosterSettings?.pitcherCap);
  }, [currentRosterSettings]);

  useEffect(() => {
    return () => {
      setRosterMax(null);
      setPitcherMax(null);
    };
  }, []);

  return (
    <div>
      <Modal
        show={isOpen}
        size="lg"
        onClose={() => {
          onCloseFn();
        }}
      >
        <ModalHeaderWithTitleAndClose
          title="Add Player/Pitcher Override"
          onClick={() => {
            onCloseFn();
          }}
          close={() => {
            onCloseFn();
          }}
        />
        <ModalBody>
          <Message>
            <div className="d-flex mb-3">
              <div className="mr-2" style={{ color: "orange" }}>
                ⓘ
              </div>
              If the pitcher's max is left empty, it is assumed there is no cap on the number of pitchers allowed.
            </div>

            <div className="d-flex">
              <div className="mr-2">
                <FormInput
                  name="title-text-input"
                  type="number"
                  isRequired
                  isDisabled={false}
                  label="Roster Max"
                  editable={true}
                  value={rosterMax}
                  onChangeFn={(value) => {
                    setRosterMax(value);
                  }}
                />
              </div>

              <FormInput
                name="title-text-input"
                type="text"
                isRequired
                isDisabled={false}
                label="Pitcher Max"
                editable={true}
                value={pitcherMax}
                onChangeFn={(value) => {
                  setPitcherMax(value);
                }}
              />
            </div>
          </Message>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex">
            <PrimaryButtonStyled
              className="mr-2"
              width={120}
              height={26}
              onClick={() => {
                handleSaveOverride();
              }}
              disabled={saveDisabled}
            >
              Save Override
            </PrimaryButtonStyled>
            <PrimaryOutlinedButtonStyled
              width={100}
              height={26}
              onClick={() => {
                onCloseFn();
              }}
            >
              Cancel
            </PrimaryOutlinedButtonStyled>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RosterRuleOverrideModal;
