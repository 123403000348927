import React from "react";
import { RouteConstants } from "./constants/RouteConstants";
import LoginContainer from "./containers/LoginContainer";
import { Route, Switch } from "react-router-dom";
import { LoginCallback, SecureRoute } from "@okta/okta-react";

const UnauthenticatedApp = () => (
  <Switch>
    <Route path={RouteConstants.LOGIN_CALLBACK} exact component={LoginCallback} />
    <SecureRoute path={RouteConstants.BASE} component={LoginContainer} />
  </Switch>
);

export default UnauthenticatedApp;
