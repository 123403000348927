import React, { useState, useEffect } from "react";
import { DataTable } from "best-common-react";
import { useGames } from "../../contexts/GamesContext";
import { useAuth } from "../../contexts/AuthContext";
import RosterSettingsApi from "../../httpClients/RosterSettingsApi";
import AuditApi from "../../httpClients/AuditApi";
import LineupApi from "../../httpClients/LineupApi";
import LineupCardVersionsModal from "./modal/LineupCardVersionsModal";
import {
  GamePkFormatter,
  GameDateFormatter,
  MatchUpFormatter,
  VenueFormatter,
  DoubleHeaderFormatter,
  HomeLineupStatusFormatter,
  AwayLineupStatusFormatter,
  DownloadCardsFormatter,
  RosterLimitFormatter,
  PrintAuditFormatter,
} from "./GamesTableFormatter";
import RosterRuleOverrideModal from "./modal/RosterRuleOverrideModal";
import DeleteRosterRuleOverrideModal from "./modal/DeleteRosterRuleOverrideModal";

const GamesTable = ({ loadingGames, setLoadingGames }) => {
  const {
    state: { games, selectedGames, rosterLimits, isAllStarGame, prevSelectedOrg, selectedOrg },
    dispatch: gamesDispatch,
  } = useGames();

  const {
    state: { isAdmin },
  } = useAuth();

  const [data, setData] = useState([...games]);
  const [loading, setLoading] = useState(true); // Initialize loading state

  const [deleteRosterRuleOverrideModalOpen, setDeleteRosterRuleOverrideOpen] = useState(false);
  const [rosterOverrideModalOpen, setRosterOverrideModalOpen] = useState(false);
  const [currentRosterSettings, setCurrentRosterSettings] = useState(null);
  const [updatedRosterOverride, setUpdatedRosterOverride] = useState("");
  const [versionsModalOpen, setVersionsModalOpen] = useState("");
  const [versionsUrls, setVersionsUrls] = useState([]);

  useEffect(() => {
    if (games.length > 0) {
      AuditApi.getAuditInfoForGames(games).then((auditResponseGames) => {
        if (auditResponseGames && auditResponseGames.length > 0) {
          const gameRosterSettingsList = auditResponseGames.map((game) => {
            return {
              game: game,
              rosterSettings: null,
            };
          });
          RosterSettingsApi.getActiveRosterSettingsByGameDateList(gameRosterSettingsList)
            .then((response) => {
              if (response) {
                auditResponseGames.forEach((game) => {
                  const gameWsettings = response.find(
                    (g) =>
                      g.game.gamePk.toString() === game.gamePk &&
                      g.game.gameDate === game.gameDate &&
                      g.activeRosterCap !== null,
                  );
                  if (gameWsettings) {
                    game.hasRosterLimit = true;
                    game.activeRosterCap = gameWsettings.activeRosterCap;
                  }
                });

                // Collect all promises for getLineups API calls
                const lineupPromises = auditResponseGames.map((game) => {
                  return LineupApi.getLineups(game.gamePk, game.teams.home.team.id, game.teams.away.team.id).then(
                    (response) => {
                      game.homeLineup = response.homeLineup;
                      game.awayLineup = response.awayLineup;
                      const selectedGame = selectedGames.find((selectedGame) => selectedGame.gamePk === game.gamePk);
                      if (selectedGame) {
                        game.isSelected = true;
                      }
                      game.isAdmin = isAdmin;
                      game.setVersionsModalOpen = setVersionsModalOpen;
                      game.setVersionsUrls = setVersionsUrls;
                      game.setRosterOverrideModalOpen = setRosterOverrideModalOpen;
                      game.setCurrentRosterSettings = setCurrentRosterSettings;
                      game.setDeleteRosterRuleOverrideOpen = setDeleteRosterRuleOverrideOpen;
                    },
                  );
                });

                // Wait for all getLineups API calls to complete
                Promise.all(lineupPromises).then(() => {
                  setData([...auditResponseGames]);
                  gamesDispatch({ type: "setSelectedGames", selectedGames: [...selectedGames] });
                  gamesDispatch({ type: "setAutoFillLineups", autoFillLineups: false });
                  gamesDispatch({ type: "setClearLineups", clearLineups: true });
                  gamesDispatch({
                    type: "resetRosterErrors",
                  });
                  setLoading(false); // Update loading state
                  setLoadingGames(false);
                });
              }
            })
            .catch(() => {
              setLoading(false); // Update loading state in case of error
            });
        }
      });
    } else {
      setData([]);
      gamesDispatch({ type: "setSelectedGames", selectedGames: [...selectedGames] });
      gamesDispatch({ type: "setAutoFillLineups", autoFillLineups: false });
      gamesDispatch({ type: "setClearLineups", clearLineups: true });
      gamesDispatch({
        type: "resetRosterErrors",
      });
      setLoading(false); // Update loading state
      setLoadingGames(false);
    }
  }, [games, rosterLimits, updatedRosterOverride]);

  const handleRowSelection = (indexes) => {
    const currentSelectedGames = [];
    indexes.forEach((index) => {
      const foundGame = data.find(
        (g) =>
          g.gamePk === index.row.gamePk &&
          g.officialDate === index.row.officialDate &&
          g.gameNumber === index.row.gameNumber,
      );
      if (foundGame) {
        foundGame.isSelected = true;
        currentSelectedGames.push(foundGame);
      }
    });
    gamesDispatch({ type: "setSelectedGames", selectedGames: [...selectedGames, ...currentSelectedGames] });
    gamesDispatch({ type: "setAutoFillLineups", autoFillLineups: false });
    gamesDispatch({ type: "setClearLineups", clearLineups: true });
    gamesDispatch({ type: "setRosterLimits", rosterLimits: null });
    gamesDispatch({
      type: "resetRosterErrors",
    });
  };

  const handleRowDeselection = (indexes) => {
    const unselectedGames = [];
    indexes.forEach((index) => {
      index.row.isSelected = false;
      unselectedGames.push(index.row.gamePk);
    });
    const currentSelectedGames = selectedGames.filter((game) => {
      if (!unselectedGames.find((g) => g === game.gamePk)) {
        return game;
      }
    });
    gamesDispatch({ type: "setSelectedGames", selectedGames: [...currentSelectedGames] });
    gamesDispatch({ type: "setAutoFillLineups", autoFillLineups: false });
    gamesDispatch({ type: "setClearLineups", clearLineups: true });
  };

  const GamesColumns = [
    {
      key: "gameDate",
      name: "Game",
      width: 200,
      formatter: GameDateFormatter,
    },
    {
      key: "teams",
      name: "",
      width: 125,
      formatter: MatchUpFormatter,
    },
    {
      key: "venue",
      name: "",
      width: 260,
      formatter: VenueFormatter,
    },
    {
      key: "",
      name: "",
      width: isAdmin ? 110 : "",
      formatter: DoubleHeaderFormatter,
    },
  ];

  const columns = isAdmin
    ? [
        ...GamesColumns,
        { key: "gamePk", name: "", width: 110, formatter: GamePkFormatter },
        { key: "", name: "Home", width: 125, formatter: HomeLineupStatusFormatter },
        { key: "", name: "Away", width: 125, formatter: AwayLineupStatusFormatter },
        { key: "", name: "Official Lineup cards", width: 200, formatter: DownloadCardsFormatter },
        // { key: "", name: "Dugout cards", width: 200, formatter: DownloadCardsFormatter },
        // { key: "", name: "Download All", width: 200, formatter: DownloadCardsFormatter },
        {
          key: "",
          name: isAdmin ? "Max Players/Pitchers" : "",
          formatter: RosterLimitFormatter,
        },
        {
          key: "",
          name: "",
          formatter: isAdmin ? PrintAuditFormatter : null,
        },
      ]
    : GamesColumns;

  return (
    <div>
      {loading ? ( // Conditional rendering based on loading state
        <div>Loading...</div>
      ) : (
        <>
          <DeleteRosterRuleOverrideModal
            isOpen={deleteRosterRuleOverrideModalOpen}
            onClose={setDeleteRosterRuleOverrideOpen}
            currentRosterSettings={currentRosterSettings}
            setFlag={setUpdatedRosterOverride}
          />
          <RosterRuleOverrideModal
            isOpen={rosterOverrideModalOpen}
            onClose={setRosterOverrideModalOpen}
            setUpdatedRosterOverride={setUpdatedRosterOverride}
            currentRosterSettings={currentRosterSettings}
          />
          <LineupCardVersionsModal isOpen={versionsModalOpen} onClose={setVersionsModalOpen} urls={versionsUrls} />
          <DataTable
            columns={columns}
            data={data}
            height={250}
            rowHeight={35}
            minColumnWidth={100}
            rowSelection={{
              showCheckbox: true,
              onRowsSelected: (indexes) => {
                handleRowSelection(indexes);
              },
              onRowsDeselected: (indexes) => {
                handleRowDeselection(indexes);
              },
              selectBy: {
                isSelectedKey: "isSelected",
              },
              enableShiftSelect: true,
            }}
          />
        </>
      )}
    </div>
  );
};

export default GamesTable;
