import axios from "axios";
import env from "../env";

const BASE_URL = env.baseURL;

class DugoutCardApi {
  static getDugoutCard = (dugoutCardinfo, startDate, endDate, teamId, gameType) => {
    let url = `${BASE_URL}/dugoutCard`;
    if (teamId) {
      url += "/" + teamId;
    }
    url += "?startDate=" + startDate;
    if (endDate) {
      url += "&endDate=" + endDate;
    }
    if (gameType) {
      url += "&gameType=" + gameType;
    }

    return axios({
      url: url,
      method: "POST",
      data: dugoutCardinfo,
      responseType: "blob", // important
    }).then((response) => {
      return response;
    });
  };
}

export default DugoutCardApi;
