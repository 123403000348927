import React, { useContext, useCallback, useState, useEffect } from "react";
import { CardBody, CardContainer, CardHeader, StickyFooterButtons } from "best-common-react";
import CardsLoadingBlocker from "../loading/CardsLoadingBlocker";
import { PrimaryOutlinedButtonStyled } from "../elements/PrimaryOutlinedButton";
import { PrimaryButtonStyled } from "../elements/PrimaryButton";
import SubSectionHeader from "../elements/SubSectionHeader";
import CardInfoSection from "./cardinfo/CardInfoSection";
import LogosSection from "./logos/LogosSection";
import LineupCardApi from "../../httpClients/LineupCardApi";
import { EditCustomCardContext } from "./contexts/EditCustomCardContext";
import FileHelper from "../../helper/FileHelper";
import { RouteConstants } from "../../constants/RouteConstants";
import { withRouter } from "react-router-dom";
import { useAlert } from "../alerts/AlertsContext";
import { trackPromise } from "react-promise-tracker";

const EditCustomCardComponent = withRouter(({ history }) => {
  const [retCustomCard, setRetCustomCard] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [generateDisabled, setGenerateDisabled] = useState(true);
  const editCustomCardContext = useContext(EditCustomCardContext);
  const showAlert = useAlert();
  const { dispatch } = editCustomCardContext;
  const { customCardInfo, edited } = editCustomCardContext.state;

  useEffect(() => {
    const customCardFromStorage = JSON.parse(localStorage.getItem("customCardInfo"));
    if (customCardFromStorage) {
      dispatch({
        type: "setCustomCardInfo",
        customCardInfo: customCardFromStorage,
      });
      setGenerateDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (edited) {
      setGenerateDisabled(true);
      setSaveDisabled(false);
    } else {
      setGenerateDisabled(false);
      setSaveDisabled(true);
    }
  }, [edited]);

  const saveCustomCard = useCallback(() => {
    parseBorders();
    trackPromise(
      LineupCardApi.saveCustomCard(customCardInfo).then((response) => {
        setGenerateDisabled(false);
        setRetCustomCard(response.entity);
        localStorage.setItem("customCardInfo", JSON.stringify(response.entity));
        setSaveDisabled(true);
        dispatch({
          type: "setCustomCardInfo",
          customCardInfo: response.entity,
        });
      }),
    );
  }, [customCardInfo]);

  const parseBorders = () => {
    if (
      !customCardInfo.outerColorC &&
      !customCardInfo.outerColorM &&
      !customCardInfo.outerColorY &&
      !customCardInfo.outerColorK
    ) {
      // Default black for now
      customCardInfo.outerColorC = 91;
      customCardInfo.outerColorM = 56;
      customCardInfo.outerColorY = 75;
      customCardInfo.outerColorK = 68;
    } else {
      customCardInfo.outerColorC = parseInt(customCardInfo.outerColorC);
      customCardInfo.outerColorM = parseInt(customCardInfo.outerColorM);
      customCardInfo.outerColorY = parseInt(customCardInfo.outerColorY);
      customCardInfo.outerColorK = parseInt(customCardInfo.outerColorK);
    }

    if (
      !customCardInfo.innerColorC &&
      !customCardInfo.innerColorM &&
      !customCardInfo.innerColorY &&
      !customCardInfo.innerColorK
    ) {
      // Default black for now
      customCardInfo.innerColorC = 91;
      customCardInfo.innerColorM = 56;
      customCardInfo.innerColorY = 75;
      customCardInfo.innerColorK = 68;
    } else {
      customCardInfo.innerColorC = parseInt(customCardInfo.innerColorC);
      customCardInfo.innerColorM = parseInt(customCardInfo.innerColorM);
      customCardInfo.innerColorY = parseInt(customCardInfo.innerColorY);
      customCardInfo.innerColorK = parseInt(customCardInfo.innerColorK);
    }
  };

  const generateCustomCard = useCallback(() => {
    if (retCustomCard !== null) {
      trackPromise(
        LineupCardApi.generateCustomCardById(retCustomCard.customCardId)
          .then((response) => {
            FileHelper.downloadFile(response, "pdf");
          })
          .catch((error) => {
            showAlert(`Error generating custom card`, `danger`);
          }),
      );
    } else if (customCardInfo.customCardId !== null) {
      trackPromise(
        LineupCardApi.generateCustomCardById(customCardInfo.customCardId)
          .then((response) => {
            FileHelper.downloadFile(response, "pdf");
          })
          .catch((error) => {
            showAlert(`Error generating custom card`, `danger`);
          }),
      );
    }
  }, [retCustomCard, customCardInfo]);

  const navToCustomCards = () => {
    history.push(RouteConstants.CUSTOM_CARDS);
  };

  return (
    <CardContainer>
      <CardHeader>Edit Card</CardHeader>
      <CardBody>
        <CardsLoadingBlocker />
        <div className="d-flex">
          <div style={{ width: "100%" }}>
            <SubSectionHeader title="Card Info" />
            <CardInfoSection />
          </div>
          <div style={{ width: "24px" }} />
          <div style={{ width: "100%" }}>
            <SubSectionHeader title="logos" />
            <LogosSection />
          </div>
        </div>
        <div className="mt-2">
          <StickyFooterButtons>
            <PrimaryButtonStyled width={131} onClick={generateCustomCard} disabled={generateDisabled}>
              Generate Card
            </PrimaryButtonStyled>
            <PrimaryOutlinedButtonStyled onClick={saveCustomCard} width={69} height={26} disabled={saveDisabled}>
              Save
            </PrimaryOutlinedButtonStyled>
            <PrimaryOutlinedButtonStyled width={80} height={26} onClick={navToCustomCards} disabled={false}>
              Cancel
            </PrimaryOutlinedButtonStyled>
          </StickyFooterButtons>
        </div>
      </CardBody>
    </CardContainer>
  );
});

export default EditCustomCardComponent;
