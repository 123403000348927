import axios from "axios";
import env from "../env";

const BASE_URL = env.baseURL + "/ebis";

class EbisApi {
  static getEbisRosterByOrgId = (orgId) => {
    const url = `${BASE_URL}/roster/${orgId}`;
    return axios.get(url).then((response) => response.data);
  };

  static getEbisRosterByOrgIdAndGamePk = (orgId, gamePk) => {
    const url = `${BASE_URL}/roster/${orgId}?gamePk=${gamePk}`;
    return axios.get(url).then((response) => response.data);
  };

  static getEbisRostersAndLineupsByOrgIds = (gamePk, orgId1, orgId2, gameType) => {
    const url = `${BASE_URL}/rosters/${gamePk}?selectedOrgId=${orgId1}&otherOrgId=${orgId2}&gameType=${gameType}`;
    return axios.get(url).then((response) => response.data);
  };

  static checkLineup = (gamePk, orgId, gameType) => {
    const url = `${BASE_URL}/checkLineup/${gamePk}?orgId=${orgId}&gameType=${gameType}`;
    return axios.get(url).then((response) => response.data);
  };
}

export default EbisApi;
