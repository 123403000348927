import axios from "axios";
import env from "../env";

const BASE_URL = env.baseURL;

class UmpireCardApi {
  static generateStatsUmpireCard = (statsUmpireCardInfo) => {
    let url = `${BASE_URL}/umpireCard/stats`;
    return axios({
      url: url,
      method: "POST",
      data: statsUmpireCardInfo,
      responseType: "blob", // important
    }).then((response) => {
      return response;
    });
  };

  static generateOfficialUmpireCard = (umpireCardDTO) => {
    let url = `${BASE_URL}/umpireCard/`;
    return axios({
      url: url,
      method: "POST",
      data: umpireCardDTO,
      responseType: "blob", // important
    }).then((response) => {
      return response;
    });
  };
}

export default UmpireCardApi;
