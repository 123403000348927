import React, { createContext, useContext, useState } from "react";
import { AlertConstants } from "best-common-react";

const AlertsContext = createContext({});

const getAlertId = () => {
  return "_" + Math.random().toString(36).substr(2, 9);
};

const AlertsProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const addAlert = (alert) => {
    const newAlerts = [...alerts];
    if (!alert.id) {
      alert.id = getAlertId();
    }
    newAlerts.push(alert);
    setAlerts(newAlerts);
  };

  const removeAlert = (id) => {
    if (id) {
      const newAlerts = alerts.filter((alert) => alert.id !== id);
      setAlerts(newAlerts);
    }
  };

  return <AlertsContext.Provider value={{ alerts, addAlert, removeAlert }}>{children}</AlertsContext.Provider>;
};

const useAlert = () => {
  const alertContext = useContext(AlertsContext);
  const { addAlert } = alertContext;

  return (message, messageType = AlertConstants.TYPES.SUCCESS) => {
    addAlert({
      type: messageType,
      text: message,
    });
  };
};

export { AlertsContext, AlertsProvider, useAlert };
