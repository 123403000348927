import React, { useEffect, useState } from "react";
import EbisTable from "./EbisTable";
import { useActiveOrgKey, useGames } from "../../../contexts/GamesContext";
import SubSectionHeader from "../../elements/SubSectionHeader";
import ButtonRow from "../../elements/ButtonRow";
import EbisCounts from "./EbisCounts";
import CardsLoadingBlocker from "../../loading/CardsLoadingBlocker";

const EbisSection = () => {
  const {
    state: { teamMap, viewableTeam, refreshEbisRoster, selectedOrg, otherOrg, clearLineups, rosterLimits },
  } = useGames();

  const orgKey = useActiveOrgKey();

  const [ebisRoster, setEbisRoster] = useState([]);
  const [rosterLimit, setRosterLimit] = useState(null);
  const [pitcherLimit, setPitcherLimit] = useState(null);

  const ebisButtonArray = [
    {
      name: "Refresh",
      content: "Refresh",
      height: 26,
      width: 91,
      onClick: () => {
        refreshEbisRoster(teamMap);
      },
      disabled: false,
      visible: true,
    },
  ];

  useEffect(() => {
    if (orgKey && teamMap.get(orgKey)) {
      const ebisRoster = [...teamMap.get(orgKey).roster, ...teamMap.get(orgKey).nonActivePlayers];
      setEbisRoster(ebisRoster);
    } else {
      setEbisRoster([]);
    }
  }, [orgKey, viewableTeam, selectedOrg, otherOrg, clearLineups, teamMap]);

  useEffect(() => {
    if (rosterLimits) {
      const rosterLimitArr = rosterLimits.split("/");
      setRosterLimit(rosterLimitArr ? rosterLimitArr[0] : 0);
      setPitcherLimit(rosterLimitArr ? rosterLimitArr[1] : 0);
    }
  }, [rosterLimits]);

  return (
    <div className="col-5">
      <SubSectionHeader title="EBIS Roster" />
      <div className="d-flex">
        <ButtonRow buttonArray={ebisButtonArray} />
        <EbisCounts
          activeRosterCount={teamMap?.get(orgKey)?.roster?.length}
          rosterCountValid={!rosterLimit || teamMap?.get(orgKey)?.roster?.length <= rosterLimit}
          activePitcherCount={teamMap?.get(orgKey)?.roster?.filter((player) => player.pitcher && !player.twp).length}
          pitcherCountValid={
            !pitcherLimit ||
            (pitcherLimit !== 0 &&
              teamMap?.get(orgKey)?.roster?.filter((player) => player.pitcher && !player.twp).length <= pitcherLimit)
          }
        />
        <CardsLoadingBlocker />
      </div>
      <EbisTable roster={ebisRoster} />
    </div>
  );
};

export default EbisSection;
