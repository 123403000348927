import axios from "axios";
import env from "../env";

const BASE_URL = env.baseURL;

class RosterSettingsApi {
  static setRosterSettings = (bulkRosterSettings) => {
    let url = `${BASE_URL}/rostersettings/`;
    return axios({
      url: url,
      method: "POST",
      data: bulkRosterSettings,
      responseType: "blob", // important
    }).then((response) => {
      return response;
    });
  };
  static getRosterSettings = () => {
    const url = `${BASE_URL}/rostersettings/`;
    return axios.get(url).then((response) => response.data);
  };

  static deleteRosterSettingById = (id) => {
    const url = `${BASE_URL}/rostersettings/${id}`;
    return axios.delete(url).then((response) => response.data);
  };

  static getActiveRosterSettingsByGameDate = (gameDate) => {
    const url = `${BASE_URL}/rostersettings/${gameDate}`;
    return axios.get(url).then((response) => response.data);
  };

  static getActiveRosterSettingsByGameDateList = (gameRosterSettingsList) => {
    return axios
      .post(`${BASE_URL}/rostersettings/getActiveRosterSettingsByGameDateList`, gameRosterSettingsList)
      .then((response) => response.data);
  };
}

export default RosterSettingsApi;
