import axios from "axios";
import env from "../env";

const BASE_URL = env.baseURL;

class LineupCardApi {
  static getCardsForRegularSeason = (lineupCardInfo, startDate, endDate, teamId, seasonType, gameType, sportId) => {
    let url = `${BASE_URL}/lineupCard`;

    if (seasonType === 2) {
      url += "/postseason";
    }
    if (teamId) {
      url += "/" + teamId;
    }
    url += "?startDate=" + startDate;
    if (endDate) {
      url += "&endDate=" + endDate;
    }
    if (gameType) {
      url += "&gameType=" + gameType;
    }
    if (sportId) {
      url += "&sportId=" + sportId;
    }
    return axios({
      url: url,
      method: "POST",
      data: lineupCardInfo,
      responseType: "blob", // important
    }).then((response) => {
      return response;
    });
  };

  getSpringTrainingForYear = (year) => {
    const url = `${BASE_URL}/lineupCard/spring/${year}`;
    return axios({
      url: url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      return response;
    });
  };

  static getUserInfo = () => {
    const url = `${BASE_URL}/user/userInfo`;
    return axios.get(url).then((response) => response.data);
  };

  static getClubInfo = (clubType) => {
    const url = `${BASE_URL}/clubs/${clubType}`;
    return axios.get(url).then((response) => response.data.entity);
  };

  static getSchedule = (startDate, endDate, teamId, seasonType, sportId) => {
    let url = `${BASE_URL}/stats/schedule`;

    if (seasonType === 2) {
      url += "/postSeason";
    }
    if (teamId) {
      url += `/${teamId}`;
    }
    url += `?startDate=${startDate}&endDate=${endDate}&sportId=${sportId}`;
    return axios.get(url).then((response) => response.data.entity);
  };

  static getAllStarSchedule = () => {
    return axios.get(`${BASE_URL}/stats/schedule/asg`).then((response) => response.data.entity);
  };

  getRoster = (id, dateStr) => {
    let url = id ? `${BASE_URL}/stats/roster/${id}` : `${BASE_URL}/stats/roster`;
    if (dateStr) {
      url += `?date=${dateStr}`;
    }
    return axios.get(url).then((response) => response.data.entity);
  };

  getBoxScore = (gamePk) => {
    const url = `${BASE_URL}/stats/boxScore/${gamePk}`;
    return axios.get(url).then((response) => response.data.entity);
  };

  getLiveFeedLineup = (gamePk) => {
    const url = `${BASE_URL}/game/livefeed/${gamePk}`;
    return axios.get(url).then((response) => response.data.entity);
  };

  static getLiveFeedTeams = (gamePk, selectedOrgId) => {
    let url = `${BASE_URL}/game/livefeed/${gamePk}/${selectedOrgId}`;
    return axios.get(url).then((response) => response.data.entity);
  };

  static saveLogoRequest = (logoRequest) => {
    const url = `${BASE_URL}/logo`;
    return axios.post(url, logoRequest).then((response) => response.data);
  };

  static saveLogoRequestForAll = (logoRequest) => {
    const url = `${BASE_URL}/logo/saveAll`;
    return axios.post(url, logoRequest).then((response) => response.data);
  };

  static getLogos = (clubId, start, end) => {
    let url = `${BASE_URL}/logo?startDate=${start}&endDate=${end}`;
    if (clubId) {
      url += `&clubId=${clubId}`;
    }
    return axios.get(url).then((response) => response.data);
  };

  static getLogoByClubId = (teamId) => {
    let url = `${BASE_URL}/logo/team/${teamId}`;
    return axios.get(url).then((response) => response.data);
  };

  static deleteLogo = (logoRequestId) => {
    let url = `${BASE_URL}/logo/${logoRequestId}`;
    return axios.delete(url).then((response) => response.data);
  };

  convertPdf = (conversionRequest) => {
    let url = `${BASE_URL}/logo/convert/pdf`;
    return axios({
      url: url,
      method: "POST",
      data: conversionRequest,
      responseType: "blob", // important
    }).then((response) => {
      return response;
    });
  };

  static getAllCustomCards = () => {
    let url = `${BASE_URL}/lineupCard/customCard`;
    return axios.get(url).then((response) => response.data);
  };

  static saveCustomCard = (customCardInfo) => {
    let url = `${BASE_URL}/lineupCard/customCard`;
    return axios.post(url, customCardInfo).then((response) => response.data);
  };

  static deleteCustomCard = (customCardId) => {
    let url = `${BASE_URL}/lineupCard/customCard/${customCardId}`;
    return axios.delete(url).then((response) => response.data);
  };

  static generateCustomCardById = (customCardId) => {
    let url = `${BASE_URL}/lineupCard/customCard/${customCardId}`;
    return axios({
      url: url,
      method: "POST",
      responseType: "blob", // important
    }).then((response) => {
      return response;
    });
  };

  static getLogoByFileName = (fileName) => {
    let url = `${BASE_URL}/logo/${fileName}`;
    return axios.get(url).then((response) => response.data);
  };

  static getAdminOverrideRule = (gamePk) => {
    let url = `${BASE_URL}/game/adminRosterOverride/${gamePk}`;
    return axios.get(url).then((response) => response.data);
  };

  static setAdminOverrideRule = (gamePk, gameDate, numPlayers, numPitchers) => {
    let url = `${BASE_URL}/game/adminRosterOverride/${gamePk}`;
    url += "?gameDate=" + gameDate + "&playersRuleOverride=" + numPlayers + "&pitchersRuleOverride=" + numPitchers;
    return axios.post(url).then((response) => response.data);
  };

  static deleteAdminOverrideRule = (gamePk) => {
    let url = `${BASE_URL}/game/adminRosterOverride/${gamePk}`;
    return axios.delete(url).then((response) => response.data);
  };

  static doubleHeaderRescheduledCheck = (game) => {
    const url = `${BASE_URL}/game/schedule/doubleheader`;
    return axios.post(url, game).then((response) => response.data);
  };
}

export default LineupCardApi;
