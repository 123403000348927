import axios from "axios";
import env from "../env";

const BASE_URL = env.baseURL + "/lineup";

class LineupApi {
  static getLineup = (gamePk, orgId) => {
    const url = `${BASE_URL}/?gamePk=${gamePk}&orgId=${orgId}`;
    return axios.get(url).then((response) => response.data);
  };

  static getLineups = (gamePk, homeOrgId, awayOrgId) => {
    const url = `${BASE_URL}/lineups?gamePk=${gamePk}&homeOrgId=${homeOrgId}&awayOrgId=${awayOrgId}`;
    return axios.get(url).then((response) => response.data);
  };

  static saveLineup = (lineup) => {
    const url = `${BASE_URL}/save`;
    return axios.post(url, lineup).then((response) => response.data);
  };
}

export default LineupApi;
