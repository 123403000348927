import React from "react";
import styled from "styled-components";
import { PrimaryOutlinedButtonStyled } from "./PrimaryOutlinedButton";
import { PrimaryButtonStyled } from "./PrimaryButton";

const DownloadIcon = styled.i.attrs(() => ({
  className: "fas fa-download",
}))`
  cursor: pointer;
  color: ${(props) => props.theme.wbcBlue};
  &&& {
    line-height: inherit;
  }
`;

const ButtonStyled = styled.div.attrs(() => ({
  className: "d-flex",
}))`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.disabled ? "#D3D3D3" : "#4c8cee")};
  cursor: pointer;
`;

const DownloadButton = ({ onClick, disabled, text, height, width, primary = false }) => {
  return (
    <div className="mr-4">
      <ButtonStyled onClick={onClick} disabled={disabled}>
        <DownloadIcon /> <div className="ml-1">{text}</div>
      </ButtonStyled>
    </div>
  );
};

export default DownloadButton;
