import React from "react";
import styled from "styled-components";
import { PrimaryOutlinedButtonStyled } from "./PrimaryOutlinedButton";
import { PrimaryButtonStyled } from "./PrimaryButton";

const DownloadIcon = styled.i.attrs(() => ({
  className: "fas fa-download",
}))`
  cursor: pointer;
  color: ${(props) => props.theme.wbcBlue};
  &&& {
    line-height: inherit;
  }
`;

const GenerateButton = ({ onClick, disabled, text, height, width, primary = false }) => {
  return (
    <div className="mr-1">
      {primary ? (
        <PrimaryButtonStyled height={height} width={width} onClick={onClick} disabled={disabled}>
          <div className="d-flex justify-content-between">
            <DownloadIcon /> <div className="ml-1">{text}</div>
          </div>
        </PrimaryButtonStyled>
      ) : (
        <PrimaryOutlinedButtonStyled height={height} width={width} onClick={onClick} disabled={disabled}>
          <div className="d-flex justify-content-between">
            <DownloadIcon /> <div className="ml-1">{text}</div>
          </div>
        </PrimaryOutlinedButtonStyled>
      )}
    </div>
  );
};

export default GenerateButton;
