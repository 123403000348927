import React, { useState, useCallback, useEffect } from "react";
import { CardBody, CardContainer, CardHeader, StickyFooterButtons } from "best-common-react";
import CustomCardsTable from "./CustomCardsTable";
import { withRouter } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";
import CardsLoadingBlocker from "../loading/CardsLoadingBlocker";
import { PrimaryOutlinedButtonStyled } from "../elements/PrimaryOutlinedButton";
import LineupCardApi from "../../httpClients/LineupCardApi";
import FileHelper from "../../helper/FileHelper";
import { trackPromise } from "react-promise-tracker";

const CustomCardsComponent = withRouter(({ history }) => {
  const [generationList, setGenerationList] = useState([]);

  useEffect(() => {
    localStorage.removeItem("customCardInfo");
  }, []);

  const createNewCustomCard = () => {
    history.push(RouteConstants.EDIT_CUSTOM_CARD);
  };

  const generateCustomCards = useCallback(() => {
    generationList.forEach((customCardId) => {
      trackPromise(
        LineupCardApi.generateCustomCardById(customCardId).then((response) => {
          FileHelper.downloadFile(response, "pdf");
        }),
      );
    });
  }, [generationList]);

  const addToGenerationList = (rows) => {
    const ids = rows.map((row) => row.row.customCardId);
    const newList = [...generationList, ...ids];
    setGenerationList(newList);
  };

  const removeFromGenerationList = (rows) => {
    const ids = rows.map((row) => row.row.customCardId);
    setGenerationList(generationList.filter((customCardId) => !ids.includes(customCardId)));
  };

  return (
    <div>
      <CardContainer>
        <CardHeader>Custom Cards</CardHeader>
        <CardBody>
          <CardsLoadingBlocker />
          <CustomCardsTable
            generationList={generationList}
            addToGenerationList={addToGenerationList}
            removeFromGenerationList={removeFromGenerationList}
          />
          <div className="mt-2">
            <StickyFooterButtons>
              <PrimaryOutlinedButtonStyled width={176} height={26} onClick={createNewCustomCard} disabled={false}>
                Create New Template
              </PrimaryOutlinedButtonStyled>
              <PrimaryOutlinedButtonStyled
                width={95}
                onClick={generateCustomCards}
                disabled={generationList.length <= 0}
              >
                Generate
              </PrimaryOutlinedButtonStyled>
            </StickyFooterButtons>
          </div>
        </CardBody>
      </CardContainer>
    </div>
  );
});

export default CustomCardsComponent;
