import axios from "axios";
import env from "../env";

const BASE_URL = env.baseURL;

class AuditApi {
  static getAuditInfoForGames = (games) => {
    let url = `${BASE_URL}/audit/`;
    return axios.post(url, games).then((response) => response.data);
  };
  static printAuditByGamePk = (gamePk) => {
    let url = `${BASE_URL}/audit/printAudit/${gamePk}`;
    return axios.get(url, {
      responseType: "blob",
    });
  };
}

export default AuditApi;
