import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeaderWithTitleAndClose } from "best-common-react";
import { PrimaryOutlinedButtonStyled } from "../../elements/PrimaryOutlinedButton";
import styled from "styled-components";
import { PrimaryButtonStyled } from "../../elements/PrimaryButton";
import LineupCardApi from "../../../httpClients/LineupCardApi";
import { useAlert } from "../../alerts/AlertsContext";

const Message = styled.div.attrs({ className: "mb-3" })`
  font-size: 1.125rem;
  font-weight: normal;
  color: #77879a;
`;

const DeleteRosterRuleOverrideModal = ({ isOpen, onClose, currentRosterSettings, setFlag }) => {
  const showAlert = useAlert();

  const deleteOverride = () => {
    LineupCardApi.deleteAdminOverrideRule(currentRosterSettings?.gamePk).then(() => {
      showAlert("Roster Rule Override Deleted", "success");
    });
  };

  return (
    <div>
      <Modal
        show={isOpen}
        size="lg"
        onClose={() => {
          onClose(false);
        }}
      >
        <ModalHeaderWithTitleAndClose
          title="Are you sure?"
          onClick={() => {
            onClose(false);
          }}
          close={() => {
            onClose(false);
          }}
        />
        <ModalBody>
          <Message>Are you sure you want to delete this roster override?</Message>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex">
            <div className="mr-2">
              <PrimaryButtonStyled
                width={100}
                height={26}
                onClick={() => {
                  deleteOverride();
                  setFlag(currentRosterSettings?.gamePk + "delete");
                  onClose(false);
                }}
              >
                Yes
              </PrimaryButtonStyled>
            </div>
            <PrimaryOutlinedButtonStyled
              width={100}
              height={26}
              onClick={() => {
                onClose(false);
              }}
            >
              Dismiss
            </PrimaryOutlinedButtonStyled>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteRosterRuleOverrideModal;
